.app {
  width: 100%;
  height: 100%;
  overflow: auto;

  @media print {
    width: auto;
    overflow: visible;
  }
}
