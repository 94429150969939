.multiLineTruncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.singleLineTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
