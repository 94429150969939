// Reusable Padding/Margin/Spacing Sizes
$spacer-xxs: 6px;
$spacer-xs: 8px;
$spacer-sm: 12px;
$spacer-md: 24px;
$spacer-lg: 38px;
$spacer-xl: 44px;
$fluid-container-padding: 15px;
$page-margin-y: 24px;
$page-margin-x: 16px;
$card-spacer-x: 12px;

// reusable rem sizes
$rem-spacer-xs: 0.1rem;
$rem-spacer-sm: 0.3rem;
$rem-spacer-md: 0.5rem;
$rem-spacer-lg: 0.7rem;
$rem-spacer-xl: 0.9rem;

// letter spacing
$letter-spacing-tiny: 0.2px;

// reusable font weight
$regular: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// Basic stuff
$basic-border-radius: 3px;
$basic-card-border-radius: 4px;
$basic-padding-margin-spacing: 10px;

// Button with left icon: 20px for width 5px for height
$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-font-size: 12px;
$btn-font-weight: $semi-bold;
$btn-border-radius: 3px;
$btn-font-size-sm: 10px;
$btn-padding-y-sm: 6px;
$btn-padding-x-sm: 12px;
$btn-border-radius-sm: 3px;
$btn-padding-y-lg: 8px;
$btn-padding-x-lg: 20px;
$btn-font-size-lg: 16px;
$btn-border-radius-lg: 3px;
$btn-font-weight: $semi-bold;
$input-padding-y: 0.625rem;
$input-padding-x: 0.75rem;

// Button with icon
$icon-button-margin: 8px;
$btn-border-radius: 3px;
$btn-border-radius-sm: $basic-border-radius;

// Button line height
$input-btn-line-height: 1;

// Card
$basic-card-margin: 15px;
$basic-card-footer-margin-y: 10px;
$card-image-height: 93px;
$card-title-margin-top: 9px;
$card-title-margin-bottom: 4px;
$card-footer-height: 40px;

// layout
$left-sidebar-width: 70px;
$left-sidebar-icon-width: 20px;
$left-sidebar-avatar-size: 42px;
$left-sidebar-avatar-margin-bottom: 14px;
$left-sidebar-nav-padding-x: 6px;

// users
$user-avatar-size: 58px;
$left-panel-content-padding: 20px;
$page-content-padding-x: 55px; // dashboard card list

$blade-width-small: 260px;
$blade-width-default: 320px;
$blade-width-regular: 400px;
$blade-width-x-large: 500px;
$top-padding: 15px;

// src/components/common/Navbar
$navbar-margin-y: 18px;
$navbar-height: 76px;

// src/components/formBuilder/Canvas/nodes
$form-builder-canvas-node-width: 220;
$form-builder-canvas-node-height: 44;

// src/app/MissionControlMethaneSolution/pages/OGMP2/OGMP2ByAsset/components/EmissionSourceComparisonChart
$emission-source-comparison-chart-height: 80px;

// Breadcrumb
$left-panel-breadcrumb-height: calc($basic-padding-margin-spacing * 3 / 2);
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 0;

// title editor
$title-editor-height: 28px;

// Icon Tabs
$icon-tabs-margin-y: $basic-padding-margin-spacing;
$icon-tabs-list-margin-x-large: 10px;
$icon-tabs-list-margin-x: 2px;
$icon-tabs-icon-size: calc($basic-padding-margin-spacing * 3 / 2);
$icon-tabs-icon-margin-bottom: $spacer-xxs;
$tab-title-margin-bottom: $basic-padding-margin-spacing;
$tab-content-margin-bottom: $basic-padding-margin-spacing;

// Map Style List
$img-list-margin-x: calc($basic-padding-margin-spacing * 3 / 2);
$img-list-margin-y: 13px;
$img-list-width: 81px;

// List with a left border
$item-list-text-padding-x: 10px;
$item-list-text-padding-y: 5px;
$item-list-item-height: 42px;
$item-list-margin-y: 3px;
$left-border-width: 3px;
$buttons-group-margin-top: calc($basic-padding-margin-spacing * 3 / 2);
$item-list-icon-width: 15px;

// Charts
$chart-margin: 15px;
$number-chart-font-size: 55px;
$status-bar-height: 50px;
$alert-padding-y: 0.625rem;
$form-switch-width: 2.25em;
$modal-padding-x: 20px;
$modal-padding-y: 13px;

// fonts
$font-size-tiny: 8px;
$font-size-extra-sm: 10px;
$font-size-sm: 0.75rem; // use rem because of bootstrap
$font-size-default: 14px;
$font-size-lg: 1rem; // use rem because of bootstrap
$font-size-heading-four: 20px;
$font-size-heading-two: 24px;

// Badges
$badge-font-size: $font-size-extra-sm;
$badge-font-weight: $semi-bold;
$badge-padding-y: 4px;
$badge-padding-x: 8px;
$badge-border-radius: 2px;

// z-indexes
$modal-overlay-z-index: 1050;
$sensor-up-logo-z-index: 1;

// gallery
$container-padding-x: $page-margin-x;
$gallery-grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Source mixin
@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

// scrollbar
$scrollbar-width: 5px;
$form-field-margin-y: 8px;
