@import 'style/sizes';
@import 'style/colors';

.tabs {
  overflow: auto hidden;
  overscroll-behavior: contain auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  -ms-scroll-chaining: contain auto;
}

.border {
  border-bottom: 1px solid $secondary-100;
}

.tabNav {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  -webkit-box-flex: 1;
}

.tabHeader {
  padding: 10px 8px;
  margin: 0;
  margin-right: 0;
  font-size: 14px;
}
