@import 'style/colors';

.container {
  flex-shrink: 0;
}

.btn {
  padding: 6px 8px !important;
}

.photosCount {
  margin-bottom: 8px;
  font-size: 12px;
  color: $secondary;
}

.squareCoverImage {
  height: 100%;
  object-fit: cover;
}
