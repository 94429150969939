@import 'style/fonts';

.dropZone {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  font-size: $font-size-sm;
  font-weight: $semi-bold;
  color: rgb(20 20 20);
  background-color: rgb(250 250 250 / 50.6%);
  border-color: rgb(206 206 206);
  border-style: dashed;
  border-width: 1px;
  border-radius: 5px;
  outline: none;
  transition: border 0.24s ease-in-out 0s;
}

.placeholder {
  align-items: center;
  padding: 34px;
  text-align: center;
}

.preview {
  align-items: left;
  padding: 10px;
}

.textButton {
  text-decoration: underline;
  cursor: pointer;
}
