@import 'style/sizes';
@import 'style/colors';

.arrayFieldTitle {
  margin-bottom: 8px;
  font-size: $font-size-lg;
  font-weight: 600;
}

.error {
  margin-bottom: 4px;
  font-size: $font-size-sm;
  line-height: 18px;
  color: $danger;
}
